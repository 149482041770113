// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "w_sz d_gR d_cv d_dt";
export var quoteParagraphCenter = "w_gS d_gS d_cv d_dv";
export var quoteParagraphRight = "w_gT d_gT d_cv d_dw";
export var quoteRowLeft = "w_sB d_bF";
export var quoteRowCenter = "w_sC d_bC";
export var quoteRowRight = "w_sD d_bG";
export var quoteWrapper = "w_gP d_gP d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "w_gQ d_gQ";
export var quoteExceptionSmall = "w_sF B_sF";
export var quoteExceptionNormal = "w_sG B_sG";
export var quoteExceptionLarge = "w_sH B_sH";
export var quoteAuthorExceptionSmall = "w_sJ B_sJ";
export var quoteAuthorExceptionNormal = "w_sK B_sK";
export var quoteAuthorExceptionLarge = "w_sL B_sL";