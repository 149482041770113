// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "C_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "C_fQ d_fQ d_by d_bH";
export var menuDesign6 = "C_t2 d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "C_t3 d_fL d_by d_dv d_bN d_bH";
export var menuRight = "C_t4 d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "C_t5 d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "C_t6 d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "C_qh d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "C_qs d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "C_t7";
export var navbarItem = "C_qj d_bw";
export var navbarLogoItemWrapper = "C_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "C_t8 d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "C_t9 d_gc d_bx d_Y d_br";
export var burgerToggle = "C_vb d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "C_vc d_gc d_bx d_Y d_br";
export var burgerInput = "C_vd d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "C_vf d_f2 d_v d_G";
export var burgerLine = "C_vg d_f0";
export var burgerMenuDesign6 = "C_vh d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "C_vj d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "C_vk d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "C_vl d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "C_vm d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "C_vn d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "C_vp d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "C_vq d_bB d_bN";
export var staticBurger = "C_vr";
export var menu = "C_vs";
export var navbarDividedLogo = "C_vt";
export var nav = "C_vv";