// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rC d_fn d_bF d_dt";
export var alignLeft = "s_pW d_fn d_bF d_dt";
export var alignDiscCenter = "s_rD d_fp d_bC d_dv";
export var alignCenter = "s_bN d_fp d_bC d_dv";
export var alignDiscRight = "s_rF d_fq d_bG d_dw";
export var alignRight = "s_pX d_fq d_bG d_dw";
export var footerContainer = "s_rG d_dV d_bV";
export var footerContainerFull = "s_rH d_dS d_bV";
export var footerHeader = "s_kd d_kd";
export var footerTextWrapper = "s_rJ d_v";
export var footerDisclaimerWrapper = "s_kl d_kl d_cj";
export var badgeWrapper = "s_rK d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "s_rL undefined";
export var footerDisclaimerLeft = "s_rM undefined";
export var verticalTop = "s_rN d_by d_bH d_bM d_bK";
export var firstWide = "s_rP";
export var disclaimer = "s_rQ d_by d_bH";
export var socialDisclaimer = "s_rR";
export var left = "s_rS";
export var wide = "s_rT d_cx";
export var right = "s_rV d_bJ";
export var line = "s_fj d_fk d_ct";
export var badgeDisclaimer = "s_rW d_bB d_bN d_bH";
export var badgeContainer = "s_rX d_by d_bG d_bN";
export var badge = "s_rY";
export var padding = "s_rZ d_c6";
export var end = "s_r0 d_bG";
export var linkWrapper = "s_r1 d_dz";
export var link = "s_mY d_dz";
export var colWrapper = "s_r2 d_cw";
export var consent = "s_f d_f d_bB d_bN";
export var media = "s_r3 d_bw d_dx";
export var itemRight = "s_r4";
export var itemLeft = "s_r5";
export var itemCenter = "s_r6";
export var exceptionWeight = "s_r7 B_ts";