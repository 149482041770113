// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_r8 d_gt d_cr";
export var heroHeaderCenter = "t_gv d_gv d_cr d_dv";
export var heroHeaderRight = "t_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "t_r9 d_gq d_cv";
export var heroParagraphCenter = "t_gr d_gr d_cv d_dv";
export var heroParagraphRight = "t_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "t_sb d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "t_sc d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "t_sd d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "t_sf d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "t_sg d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "t_sh B_sh";
export var heroExceptionNormal = "t_sj B_sj";
export var heroExceptionLarge = "t_sk B_sk";
export var Title1Small = "t_sl B_sl B_s1 B_s2";
export var Title1Normal = "t_sm B_sm B_s1 B_s3";
export var Title1Large = "t_sn B_sn B_s1 B_s4";
export var BodySmall = "t_sp B_sp B_s1 B_tk";
export var BodyNormal = "t_sq B_sq B_s1 B_tl";
export var BodyLarge = "t_sr B_sr B_s1 B_tm";