// extracted by mini-css-extract-plugin
export var lbContainerOuter = "F_wj";
export var lbContainerInner = "F_wk";
export var movingForwards = "F_wl";
export var movingForwardsOther = "F_wm";
export var movingBackwards = "F_wn";
export var movingBackwardsOther = "F_wp";
export var lbImage = "F_wq";
export var lbOtherImage = "F_wr";
export var prevButton = "F_ws";
export var nextButton = "F_wt";
export var closing = "F_wv";
export var appear = "F_ww";